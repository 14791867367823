<template>

    <v-chip
        class="person-label immutable-text"
        small
        label
        @click.stop="openDocument"
    >
        <div 
            class="if-more-tooltip-chip"
            v-tooltip.top-center="name"
        >
            <v-icon :color="iconColor">{{ icon }}</v-icon>
            <div>{{ Name }}</div>
        </div>
        <div class="pl-actions-wrapper">
            <span
                v-if="canDelete"
                v-tooltip.top="$t('Удалить')"
                @click.stop="onDelete"
            >
                <i class="fas fa-trash dark-gray-color"></i>
            </span>
        </div>
    </v-chip>

</template>

<script>

export default {
    name: "DocumentTypeChip",
    data() {
            return {
                type: 'Chancellery|Documents.Document'
            }
    },
    props: {
        id: {
            type: String,
            default: null
        },
        linkId: {
            type: String,
            default: null
        },
        name: {
            type: String,
            default: null
        },
        formatter: {
            type: Function,
            default: null
        },
        icon: {
            type: String,
            default: "fas fa-file"
        },
        iconColor: {
            type: String,
            default: "#494F59"
        },
        canDelete: {
            type: Boolean,
            default: false
        },
        isLinkedDocument: {
            type: Boolean,
            default: false
        },
        sourceDocumentId: {
            type: String,
            default: null
        },
        isCommonDocument: {
            type: Boolean,
            default: false
        },
        isDirectLink: {
            type: Boolean,
            default: true
        },
    },
    computed: {
        Name () {
           if (this.formatter)
                return this.formatter(this.name);

            if (this.name.length > 40)
                return `${this.name.substring(0, 39)}...`;
            else
                return this.name;
        }
    },
    methods: {
        openDocument() {
            if (!!this.id && this.id.length > 0 && !!this.type && this.type.length > 0)
                this.isLinkedDocument
                    ? this.isDirectLink
                        ? this.$eventBus.$emit('open-linked-document', { type: this.type, common: this.isCommonDocument, linkType: 'DirectLink', linkId: this.linkId, sourceDocumentId: this.sourceDocumentId })
                        : this.$eventBus.$emit('open-linked-document', { type: this.type, common: this.isCommonDocument, linkType: 'NotDirectLink', documentId: this.id, sourceDocumentId: this.sourceDocumentId })
                    : this.$eventBus.$emit('open-document', { id: this.id, type: this.type });
            else
                this.$notify.warning(this.$t('Данные_в_ссылке_на_документ_не_соответствуют_ожидаемому_формату'));
        },
        async onDelete() {
            if (this.linkId)
                this.$eventBus.$emit('delete-document-link', this.linkId);
        },
    }
}
</script>